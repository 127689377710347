aside{
    margin-top: 2px;
    
}

.menuDetails{

    /* border: 1px solid red; */
    height: 86vh;
    overflow-x: hidden;
    overflow-y: scroll;
}



.sideBar{
    background-color: #f6f6f7;
    /* border-right: 2px solid #ebebeb; */
    min-height: 86vh;
}
.nav-pills .nav-link {
    display: block;
    padding: .5rem 2rem !important;
    border-radius: 0px;
    color: black;
    border: none;
    outline-offset: 0 !important;
    outline-color: none;
    outline: none;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(244,245,245) !important;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: black;
    font-weight: 500;
    background-color: #dee0e0;
}
/* table,.table td, .table th {
    border: none !important;
} */
table tbody tr:hover {
    color: none;
    background-color:none !important;
}

.itemDisplyIDDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemDisplyIDDiv p {
    vertical-align:middle;
    background-color:black;
    border-radius: 50%;
    text-align:center;
    color:white;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 8px 3px 8px;

}

.dishDescription{
    font-size: 12px !important;
    color: rgb(190, 111, 71);
}

.dishSecondryDescription{
    font-size: 12px !important;
}

@media (max-width: 767.98px) {

    .menuDetails{

        /* border: 1px solid red; */
        height: 80vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
 
    .itemDisplyIDDiv p {
        font-size: 12px;
    }
 }